<template>
	<div id="vue-app" v-cloak>

<phi-post-editor
            v-if="post && isReady"
            :post="post"
            date-format="llll"
            :allow-html="true"
            :editor-config="editorConfig"
            :auto-save="!isSending"
        ></phi-post-editor>

</div>
</template>
<script>
import app from '@/store/app.js';
import PhiPostEditor from '@/components/Phi/Post/Editor.vue';

export default {
	name: 'vue-app',
	components: {
		PhiPostEditor
	},
    mounted: function() {
        this.loadPost();
    },

    data: function() {
        return {
            postId: 'vlklz98qx4y',
            post: null,
            editorConfig: {
                plugins: ['code link image imagetools colorpicker textcolor fullscreen textcolor table lists contextmenu table preview paste'],
                toolbar: ['formatselect | alignleft aligncenter alignright alignjustify forecolor backcolor | bold italic underline | bullist numlist | link image table  | styleselect removeformat | preview code'],
                contextmenu: "cut copy paste | link image inserttable | cell row column deletetable",
                browser_spellcheck: true, 
                relative_urls: false, 
                convert_urls: false, 
                remove_script_host: false,
            },
            isSending: false
        }
    },

    methods: {
        loadPost: function() {
            app.api
                .get("/posts/" + this.postId)
                .then(function(response) {
                    this.post = response;
                }.bind(this));
        }
    }
};
</script>